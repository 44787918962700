import "bootstrap";
import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "../sass/_common.scss";
import "./emailsubscription";

(function mainScript() {
	"use strict";

	const offcanvasToggle = document.querySelector(
		'[data-bs-toggle="offcanvas"]'
	);
	const offcanvasCollapse = document.querySelector(".offcanvas-collapse");

	offcanvasToggle.addEventListener("click", function () {
		offcanvasCollapse.classList.toggle("open");
	});

	const pathname = location.pathname.split("/").pop();

	// Add active state to navbar
	$(".navbar li.active").removeClass("active");
	$('.navbar a[href="/' + pathname + '"]')
		.closest("li")
		.addClass("active");
	$('.navbar a.dropdown-item[href="/' + pathname + '"]')
		.closest("li.nav-item")
		.addClass("active");
	$('.navbar a.dropdown-item[href="/' + pathname + '"]').addClass("active");
	$('.navbar a[href="/' + pathname + '.html"]')
		.closest("li")
		.addClass("active");
	$('.navbar a.dropdown-item[href="/' + pathname + '.html"]')
		.closest("li.nav-item")
		.addClass("active");
	$('.navbar a.dropdown-item[href="/' + pathname + '.html"]').addClass(
		"active"
	);

	// Add active state to relative path
	$(".navbar li.active").removeClass("active");
	$('.navbar a[href="./' + pathname + '"]')
		.closest("li")
		.addClass("active");
	$('.navbar a.dropdown-item[href="./' + pathname + '"]')
		.closest("li.nav-item")
		.addClass("active");
	$('.navbar a.dropdown-item[href="./' + pathname + '"]').addClass("active");
	$('.navbar a[href="./' + pathname + '.html"]')
		.closest("li")
		.addClass("active");
	$('.navbar a.dropdown-item[href="./' + pathname + '.html"]')
		.closest("li.nav-item")
		.addClass("active");
	$('.navbar a.dropdown-item[href="./' + pathname + '.html"]').addClass(
		"active"
	);
})();

$(".partner-logos").slick({
	arrows: false,
	prevArrow: false,
	nextArrow: false,
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 4,
	autoplay: true,
	autoplaySpeed: 2000,

	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: false,
				dots: true,
			},
		},
	],
});

$("#ether-nav ul li a").on("click", function (event) {
	var $anchor = $(this);
	$("html, body").animate(
		{
			scrollTop: $($anchor.attr("href")).offset().top + "px",
		},
		1000
	);
	event.preventDefault();
});

import loader from "../images/loader.svg";

import logo1 from "../images/logo1.png";

import logo2 from "../images/logo2.png";

import logo3 from "../images/logo3.png";

import logo4 from "../images/logo4.png";

import logo5 from "../images/logo5.png";

import logo_footer from "../images/logo_footer.png";

import logo_header from "../images/logo_header.png";

import news_thumb from "../images/news-thumb.png";

import favicon from "../images/favicon.png";

import twitter from "../images/twitter.png";

import discord from "../images/discord.png";

import reddit from "../images/reddit.png";

import success from "../images/success.png";
